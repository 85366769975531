@import "src/colors";

.projectContainer {

  max-width: calc(100% - 50px);
  margin-left: 50px;
  box-sizing: border-box;
  overflow: auto;

  &.stickyNav {

    margin-left: 0;
    max-width: 100%;

  }

  .project:not(.activeProject) {
    opacity: 0.25;
  }

}

.categoryContainer {
  max-width: 100%;
  margin: 0 5px;
  box-sizing: border-box;
  overflow: auto;
}

.inputMenu {
  background: #f5f5f5;
  -webkit-box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.25);
  position: relative;

  .bpMenu {
    overflow: hidden;
  }

  .isolateButton {
    position: absolute;
    top: 47%;
    left: 100%;
    z-index: 10;
    border-radius: 0 5px 5px 0;
    padding: 10px 3px 10px 0;
    $border: 1px solid rgba(128, 128, 128, 0.61);
    border-top: $border;
    border-right: $border;
    border-bottom: $border;
  }

}

.tooltipIcon {
  position: absolute;
  top: 0;
  right: 3%;
}

.tooltipModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 500px;
  background: rgba(0,0,0,0.25);

  .content {
    position: relative;
    width: 74%;
    overflow-y: auto;
    background: $mainGreen;
    height: 80%;
    left: 13%;
    top: 10%;
    border-radius: 15px 0 0 15px;
    padding: 1em;
    color: white;

    .closeButton {
      position: absolute;
      top: 1%;
      right: 1%;
    }

  }

}

:global .MuiMenuItem-root :local .modelTypeSelectorText {
  padding-left: 8px;
}
