.gridContainer {
  width: 100%;
  min-height: 65%;

  .deleteAccount {
    background: #ea5656;
    color: white;
    max-width: 300px;
  }

}
