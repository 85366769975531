@import "./colors.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.headerBar {
  padding: 13px 0 13px 0;
  background: $mainGreen;
  box-shadow: 0 0 3px 0 gray;
  color: white;
}

$scrollbarColor: #3AB54A;

@media (pointer: fine) {


  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }

  *::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: $scrollbarColor;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $scrollbarColor;
    //border: 2px solid #555555;
  }

}
