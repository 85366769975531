.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  max-width: 800px;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  max-height: 90%;
  overflow: auto;

  .closeButton {
    position: absolute;
    right: 0;
    top: 10px;
  }

}
