@import 'src/colors';

.listItem {
  border-bottom: 1px solid rgba(161, 161, 161, 0.66);
  padding-left: 5px;
}

.bpList {
  max-height: 40vh;
  overflow: auto;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 5px;
}

.logo {
  width: 110px;

  & > img {
    display: block;
    width: 100%;
  }

}

.columnGroup {
  background: $mainGreen;

  &:not(:last-of-type) {
    border-right: 1px solid white;
  }
}

$cellBorder: 1px solid rgba(128, 128, 128, 0.5);

.collapsedCell {
  opacity: 0.1;
  border-right: $cellBorder;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
    pointer-events: none; /* Allow clicking through the overlay */
    border-radius: inherit; /* Maintain the border-radius of the element */
  }

}

.columnGroupEnd {
  border-right: $cellBorder;
}

.nameCol {
  overflow: hidden;
  min-width: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.advancedTable th, .advancedTable td {
  text-align: center;
  position: relative;
}

.hideColumnsButton {
  position: absolute;
  right: 4%;
  top: 0;
  height: 100%;
  display: flex;
}
