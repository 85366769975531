@import "src/colors";

.navIcon {
  padding-right: 10px;
}

.navGrid {
  height: 100%;
  padding: 0 0 4vh 0;
}

.bgGreen {
  background: $mainGreen;
}

.mcmaccxLogo {
  width: 78%;
  position: relative;
}

.navDrawer {
  background: $mainGreen;
  color: white;
  height: 100%;

  svg {
    color: white;
  }
}

.partnerImg {
  object-fit: contain;
  max-height: 80px;
  width: 80%;
}
